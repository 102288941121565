import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ClaytonLogo from '@components/Shared/ClaytonLogo/ClaytonLogo';
import ChevronDown from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import Facebook from 'pubweb-smokey/dist/images/svg/iconography-social/facebook.svg';
import Instagram from 'pubweb-smokey/dist/images/svg/iconography-social/instagram.svg';
import Pinterest from 'pubweb-smokey/dist/images/svg/iconography-social/pinterest.svg';
import YouTube from 'pubweb-smokey/dist/images/svg/iconography-social/youtube.svg';
import TikTok from 'pubweb-smokey/dist/images/svg/iconography-social/tiktok.svg';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import { DXAccountContext } from '@contexts/DXAccountContext';

import SectionLink from '../SectionLink/SectionLink';
import { FooterStyles } from './Footer.styled';
import { getCareerSiteUrl } from '@utils/config';

const Footer = ({
  internalLink = true,
  addSiteUrl = false,
  siteUrlOverride = '',
  ssr = false,
}) => {
  const [shopMenuOpenMobile, setShopMenuOpenMobile] = useState(false);
  const [discoverMenuOpenMobile, setDiscoverMenuOpenMobile] = useState(false);
  const [connectMenuOpenMobile, setConnectMenuOpenMobile] = useState(false);
  const [accountMenuOpenMobile, setAccountMenuOpenMobile] = useState(false);
  const accountContext = useContext(DXAccountContext);

  return (
    <FooterStyles>
      <footer>
        <div className="shop">
          <div
            className="h5 footer-heading"
            onClick={() => setShopMenuOpenMobile(!shopMenuOpenMobile)}
          >
            Shop
            <div className={shopMenuOpenMobile ? 'up' : 'down'}>
              <ChevronDown />
            </div>
          </div>
          <ul
            className={
              shopMenuOpenMobile ? 'menu-visible-mobile' : 'menu-hidden-mobile'
            }
          >
            <li>
              <SectionLink
                to="/find-a-home/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Homes
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/locations/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Locations
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/get-inspired/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Inspiration
              </SectionLink>
            </li>
          </ul>
        </div>
        <div className="discover">
          <div
            className="h5 footer-heading"
            onClick={() => setDiscoverMenuOpenMobile(!discoverMenuOpenMobile)}
          >
            Discover
            <div className={discoverMenuOpenMobile ? 'up' : 'down'}>
              <ChevronDown />
            </div>
          </div>
          <ul
            className={
              discoverMenuOpenMobile
                ? 'menu-visible-mobile'
                : 'menu-hidden-mobile'
            }
          >
            <li>
              <SectionLink
                to="/studio/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Studio
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/about/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                About
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/sustainability/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Sustainability
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/social-responsibility/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Social Responsibility
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/dream-to-reality/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Dream to Reality
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/about/conflict-minerals/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Conflict Minerals
              </SectionLink>
            </li>
          </ul>
        </div>

        <div className="connect">
          <div
            className="h5 footer-heading"
            onClick={() => setConnectMenuOpenMobile(!connectMenuOpenMobile)}
          >
            Connect
            <div className={connectMenuOpenMobile ? 'up' : 'down'}>
              <ChevronDown />
            </div>
          </div>
          <ul
            className={
              connectMenuOpenMobile
                ? 'menu-visible-mobile'
                : 'menu-hidden-mobile'
            }
          >
            <li>
              <SectionLink
                to="/contact-us/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Contact Us
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/newsroom/"
                className="caption newsroom"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                onClick={() => {
                  pushGTMEvent('newsroom');
                }}
                ssr={ssr}
              >
                Newsroom
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/builder-resources/"
                className="caption builder-resources"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                onClick={() => {
                  pushGTMEvent('builder-resources');
                }}
                ssr={ssr}
              >
                Builder Resources
              </SectionLink>
            </li>
            <li>
              <a
                href={getCareerSiteUrl()}
                className="caption careers-link"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  pushGTMEvent('careers-link');
                }}
              >
                Careers
              </a>
            </li>
            <li>
              <SectionLink
                to="/subsidiaries/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                Subsidiaries
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to="/faq/"
                className="caption"
                internalLink={internalLink}
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                FAQs
              </SectionLink>
            </li>
          </ul>
        </div>

        <div className="account">
          <div
            className="h5 footer-heading"
            onClick={() => setAccountMenuOpenMobile(!accountMenuOpenMobile)}
          >
            My Account
            <div className={accountMenuOpenMobile ? 'up' : 'down'}>
              <ChevronDown />
            </div>
          </div>
          <ul
            className={
              accountMenuOpenMobile
                ? 'menu-visible-mobile'
                : 'menu-hidden-mobile'
            }
          >
            <li>
              <SectionLink
                to={
                  accountContext.state?.account ? '/portal/' : '/myhome-planner'
                }
                className="caption portalLink"
                internalLink={
                  internalLink
                    ? accountContext.state?.account
                      ? false
                      : true
                    : false
                }
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
              >
                MyHome Account
              </SectionLink>
            </li>
            <li>
              <SectionLink
                to={
                  accountContext.state?.account
                    ? '/portal/favorites/'
                    : '/myhome-planner'
                }
                className="caption portalLink"
                internalLink={
                  internalLink
                    ? accountContext.state?.account
                      ? false
                      : true
                    : false
                }
                addSiteUrl={addSiteUrl}
                siteUrlOverride={siteUrlOverride}
                ssr={ssr}
                onClick={() =>
                  accountContext.actions.setPostLoginRedirectPath(
                    '/portal/favorites/'
                  )
                }
              >
                My Favorites
              </SectionLink>
            </li>
            <li>
              <a
                href="https://p.widencdn.net/9vm128/Homeowner-Manual/"
                className="caption homeowner-resources"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  pushGTMEvent('homeowner-manual');
                }}
              >
                Homeowner Manual
              </a>
            </li>
            {/* is an external link for use on external sites */}
            <li>
              <a
                href="https://www.claytonhomecare.com/"
                className="caption home-care-guide-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Home Care Guide
              </a>
            </li>
            {/* use below for home owner manual link if it should change from the widen link in the future. */}
            {/* <li><a href={(addSiteUrl) ? `${getSiteUrl()}/Homeowner-Manual/` : `/Homeowner-Manual`} className="caption" target="_blank" rel="noopener">Homeowner Resources</a></li>                         */}
          </ul>
        </div>

        <div className="logo-social">
          <div className="clayton-logo">
            <ClaytonLogo letterColor="#fff" rooftopColor="#fff" />
          </div>

          <ul className="social-icons">
            <li>
              <a
                href="https://www.facebook.com/ClaytonHomes"
                className="caption facebook-icon"
                onClick={() => {
                  pushGTMEvent('facebook-icon');
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div role="img" aria-label="Facebook">
                  <Facebook />
                </div>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/clayton/"
                className="caption instagram-icon"
                onClick={() => {
                  pushGTMEvent('instagram-icon');
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div role="img" aria-label="Instagram">
                  <Instagram viewBox="0 0 16 16" />
                </div>
              </a>
            </li>
            <li>
              <a
                href="https://www.pinterest.com/claytonhomes/"
                className="caption pinterest-icon"
                onClick={() => {
                  pushGTMEvent('pinterest-icon');
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div role="img" aria-label="Pinterest">
                  <Pinterest viewBox="0 0 16 16" />
                </div>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/user/claytonhomes"
                className="caption youtube-icon"
                onClick={() => {
                  pushGTMEvent('youtube-icon');
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div role="img" aria-label="YouTube">
                  <YouTube viewBox="0 0 16 16" />
                </div>
              </a>
            </li>
            <li>
              <a
                href="https://www.tiktok.com/@claytonhomes"
                className="caption tiktok-icon"
                onClick={() => {
                  pushGTMEvent('tiktok-icon');
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div role="img" aria-label="TikTok">
                  <TikTok />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </footer>

      <div className="legal-privacy">
        <div className="legal-privacy-inside">
          <p className="xsmall">
            &copy; 1998-{new Date().getFullYear()} Clayton.
          </p>
          <p className="legal-privacy-links xsmall">
            <SectionLink
              to="/legal/"
              target="_blank"
              className="xsmall"
              internalLink={internalLink}
              addSiteUrl={addSiteUrl}
              siteUrlOverride={siteUrlOverride}
              ssr={ssr}
            >
              Legal
            </SectionLink>
            <SectionLink
              to="/privacy/"
              target="_blank"
              className="xsmall"
              internalLink={internalLink}
              addSiteUrl={addSiteUrl}
              siteUrlOverride={siteUrlOverride}
              ssr={ssr}
            >
              Privacy
            </SectionLink>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
              }}
            >
              Do Not Sell or Share My Personal Information
            </a>
          </p>
        </div>
      </div>
    </FooterStyles>
  );
};

Footer.propTypes = {
  addSiteUrl: PropTypes.bool,
  ccpaOverride: PropTypes.string,
  internalLink: PropTypes.bool,
  siteUrlOverride: PropTypes.string,
  ssr: PropTypes.bool,
};

export default Footer;
